import * as React from 'react';

import Button from '../button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Section from '../section';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

export default function Callout() {
  return (
    <Section theme="purple500" style={{ padding: `5rem 0` }}>
      <div className="row justify-content-center">
        <div className="col-lg-10 col-xl-8 text-center">
          <p className="heading-xl color-white">Get started today</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-8 col-xl-6 text-center">
          <p
            className="heading-sm color-white text-center"
            style={{ marginTop: '0', marginBottom: '2rem' }}>
            Sign up free and explore the Interact quiz platform today.
          </p>
          <Button
            theme="white"
            size="large"
            href={`${process.env.GATSBY_DASHBOARD_URL}/register`}
            style={{ gap: '8px' }}>
            Start for free <FontAwesomeIcon icon={faArrowRight} />
          </Button>
          <p
            className="text-body color-white text-center"
            style={{ marginTop: '8px', fontSize: '12px', opacity: '0.8' }}>
            No credit card required
          </p>
        </div>
      </div>
    </Section>
  );
}
